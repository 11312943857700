import "./Loading.css";

export default function Loading() {
	return (
		<div className="Loading">
			<div className="loader">
				<div className="loader-inner">
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
					<div className="loader-line-wrap">
						<div className="loader-line"></div>
					</div>
				</div>
			</div>
		</div>
	);
}
