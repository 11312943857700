export const getLink = (title: string) => encodeURIComponent(title.replace(/\s/g, "-"));

export const getURL = (str: string) => str?.replace(/[^a-zA-Z0-9-]/g, "");

export const formatString = (str: string) => str.replaceAll("-", " ");

export const stripTag = (str: string) => {
    var div = document.createElement("div");
    div.innerHTML = str;
    return div.textContent || div.innerText || "";
}